import React from "react";

export default function BattleOther(props) {
  const notThisTitle = props.title;
  const allBattles = require("../pages/battles.json");

  const anotherOne = allBattles.filter(function (el) {
    return el.title !== notThisTitle;
  });

  const randomIndexy = Math.floor(Math.random() * anotherOne.length);

  const infoForBattle = anotherOne[randomIndexy];

  if (!infoForBattle) return null;

  return (
    <div className="other-node-wrap-sidebar">
      <h4>Other Taco Battles </h4>

      <div className="other-options">
        <div className="side-by-side">
          <span className="mr-middle">
            <i className="fa-solid fa-chess-knight"></i>
          </span>
          <img src={infoForBattle["tacoA"].img} />
          <img src={infoForBattle["tacoB"].img} />
        </div>
        <div className="title">
          <a href={infoForBattle.link}>{infoForBattle.title}</a>
        </div>
      </div>
    </div>
  );
}
