import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RestaurantCard from "../../components/RestaurantCard";

import BattleOther from "../../components/BattleOther.js";

const allRecommendations = require("../recommendations.json");
const allTacoBattles = require("../battles.json");

const tacoBattlesInfo = allTacoBattles[2]; //

const infoForTacoA = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoA"]["restaurant"];
})[0];

const infoForTacoB = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoB"]["restaurant"];
})[0];

const TacoAVersusTacoB = () => {
  return (
    <>
      <Head
        title={`Tacos Battles: ${tacoBattlesInfo.title}`}
        description={`Austin Tacos Battles present ${tacoBattlesInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar taco-battles-node">
            <div className="primary-wrapper">
              <h1>{`Tacos Battles: ${tacoBattlesInfo.title}`}</h1>

              <div className="battle-groups-the-wrapper">
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoA.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoA.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoA.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoA.link}>
                      {tacoBattlesInfo.tacoA.restaurant}
                    </a>
                  </div>
                </div>
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoB.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoB.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoB.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoB.link}>
                      {tacoBattlesInfo.tacoB.restaurant}
                    </a>
                  </div>
                </div>
              </div>

              {/* + */}
              <h2>How the Tacos Compare</h2>
              <p>
                It was a perfect saturday afternoon, as we headed to Granny's
                for their Veggie Taco. We were a bit skeptical about mixing eggs
                and cactus, but then the adventure began. The flavor was huge
                and you could immediately tell the effort that went into mixing
                the right things together.
              </p>

              <p>
                Next we hit up The Vegan Nom for their infamous "Grilled Avocado
                Reale" taco. Granny's veggie taco packed a flavor punch, but the
                avaocado reale is swinging for the fences. It mixes a
                combination that is hard to beat in the veggie or non-veggie
                space. The caramelized onions were great, but when combined with
                the crema and cilantro.. wow.
              </p>

              {/* + */}
              <h2>The Winner is... {tacoBattlesInfo.tacoB.nameOfTaco} </h2>
              <p>
                Oh the emotional rollercoaster of the veggie taco, so many
                highs.. and highs. Obviously both are the top of their respected
                tiers, but we have to give the edge in flavor to the "Grilled
                Avocado Reale" because so many flavors just frankly impressed
                us. The way the pepperjack hits you with the beans and onions,
                just really rich and lots going on.
              </p>

              <h3>Judge for yourself and let us know!</h3>

              <div className="taco-the-battle-cards">
                <div className="taco-a-left">
                  <RestaurantCard data={infoForTacoA}></RestaurantCard>
                </div>
                <div className="taco-b-right">
                  <RestaurantCard data={infoForTacoB}></RestaurantCard>
                </div>
              </div>
            </div>

            <div className="right-sidebar">
              <BattleOther title={tacoBattlesInfo.title}></BattleOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TacoAVersusTacoB;
